import Dexie from 'dexie';
import axios from "axios";

const apiUrl = "https://admin-draw-video.masscom-ci.com/api"

export const db = new Dexie('SurveyDB');

db.version(1).stores({
    submissions: '++id,email',
});

db.version(3).stores({
    emails: '++id,email',
});

db.version(2).stores({
    questions: '++id,question_text,question_type,options',
});

db.version(39).stores({
    maxParticipant: '++id,max_participant',
});

db.version(40).stores({
    advertisements: "++id,advertisement_id,link,name"
})

db.version(41).stores({
    videos: "++id,name"
})

db.open();

export const addSubmission = (submission) => {
    return db.submissions.add(submission);
};

export const addMaxPart = (maxPart) => {
    return db.maxParticipant.add(maxPart);
};

export const syncSubmissions = async () => {
    const submissions = await db.submissions.toArray();
    if (submissions.length === 0) return;
    try {
        const body = {
            submissions: submissions
        }

        await axios.post(`${apiUrl}/bulk-submit`, body)

        await db.submissions.clear()
        await db.submissions.bulkDelete(submissions.map((s) => s.id));

    } catch (error) {
        // toast.error("Max participant reached")
    }
};

export const fetchLatestAdvertisement = async () => {
    try {
        const response = await axios.get(`${apiUrl}/advertisement/latest`);
        if (response.status === 200) {
            return response.data.data;
        }
        return {}
    } catch (error) {
        console.error(error)
        return {}
    }
}


export const addVideoView = (video) => {
    db.videos.add({video_id: video.id, name: video.name})
}

export const syncVideoViews = async () => {
    const videos = await db.videos.toArray()
    const json = JSON.stringify(videos)
    console.log(json)
    const response = await axios.post(`${apiUrl}/videos/sync`, json)
    if (response.status === 200) {
        await db.videos.clear()
    }
}

export const addAdvertisementView = (advertisement) => {
    db.advertisements.add({advertisement_id: advertisement.id, name: advertisement.name, link: advertisement.link})
}

export const syncAdvertisementViews = async () => {
    const advertisements = await db.advertisements.toArray()
    // const advertisements = await db.advertisements.toArray()
    // const json = JSON.stringify(advertisements)
    // const response = await axios.get(`${apiUrl}/advertisement/sync`, json);
    let data = ""

    for(let adv in advertisements){
        //mettre les ids sous forme 1,2,3
        data += advertisements[adv].advertisement_id + ","
    }

    const response = await axios.get(`${apiUrl}/advertisement/sync?data=${encodeURIComponent(data)}`);
    // const response = await axios.post(`http://admin-draw-video.masscom-ci.com/api/advertisement/sync`, json);
    if (response.status === 200) {
        await db.advertisements.clear()
    }
}

export const fetchQuestions = async () => {
    try {
        const response = await fetch(`${apiUrl}/questions`);
        if (response.ok) {
            const questions = await response.json();
            await db.questions.clear()
            console.log(questions)

            if (!questions?.data?.questions) {
                await db.questions.clear()
            } else {
                await db.questions.put(questions?.data?.questions);
            }
        }
    } catch (error) {
        console.log('Error:', error);
    }
};

export const getStoredQuestions = async () => {
    try {
        return await db.questions.toArray()
    } catch (error) {
        console.log('Error:', error);
        return [];
    }
};

export const addParentSubmission = (submission) => {
    return db.parent_submissions.add(submission);
};


export const syncParentSubmissions = async () => {
    const submissions = await db.parent_submissions.toArray();
    if (submissions.length === 0) return;
    try {
        const body = {
            submissions: submissions
        }

        await axios.post(`${apiUrl}/parrain/bulk-submit`, body)

        await db.parent_submissions.clear()
        await db.parent_submissions.bulkDelete(submissions.map((s) => s.id));

    } catch (error) {
        // toast.error("Max participant reached")
    }
};
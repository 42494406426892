import CloseIcon from "@mui/icons-material/Close";
import * as React from "react";
import {useEffect, useState, useRef} from "react";
import {addAdvertisementView, fetchLatestAdvertisement, syncAdvertisementViews} from "./db";
import {useQuery} from "@tanstack/react-query";
import {useModalStore} from "./store/modalStore";
import {useNavigate} from "react-router-dom";
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import axios from "axios";

const style = {
    position: 'absolute',
    // top: '50%',
    // left: '50%',
    width: '100vw',
    height: '80vh',
    // transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
};


const AdPopup = ({setAdPopupOpen}) => {
    const {nextUrl} = useModalStore()
    const navigate = useNavigate()
    const {isPending, isError, data, error} = useQuery({
        queryKey: ['advertisement'],
        queryFn: fetchLatestAdvertisement
    })
    const [canBeSkipped, setCanBeSkipped] = useState(false)

    const videoRef = useRef(null);

    let cachedAd = "advertisement/advertisement-example.mp4"

    
    let alreadyCount = false

    const countViewAdvertisement = async () => {
        try {
            const response = await axios.get(`https://admin-draw-video.masscom-ci.com/api/advertisement/count`);
            alreadyCount = true
        } catch (error) {
            console.error(error)
        }
    };


    var waitTime = 15; //en seconde

    //aficher ignorer mais sans_action
    // setCanBeSkipped(true)

    useEffect(() => {
        setCanBeSkipped(false)
        setTimeout(() => {
            setCanBeSkipped(true)
        }, waitTime*1000)
        syncAdvertisementViews().then(r => r)

        const playVideo = () => {
            console.log("PALY VIDEO")
            console.log(videoRef)
            if (videoRef.current) {
              setTimeout(() => {
                videoRef.current.play().catch(error => console.error('Autoplay error:', error));
              }, 500); // Adjust the delay as needed
            }
          };
      
          // Play the video on page load
          playVideo();

    }, []);

    useEffect(() => {
        if (data) {
            addAdvertisementView(data)
            if(!alreadyCount){
                countViewAdvertisement();
            }
        }
    }, [data])

    const handleSkip = () => {
        console.log("skip")
        console.log(nextUrl)
        
        if (nextUrl) {
            navigate(nextUrl)
        }
        else{
            navigate("/entry")
        }
        if (setAdPopupOpen) {
            setAdPopupOpen(false)
        }
    }

    if (isPending) {
        return <span>Loading...</span>
    }

    console.log(data.link ?? cachedAd)
    console.log("PUB")
    console.log(data)

    return (
        <div style={{position: "absolute", top: 0, left: 0, backgroundColor: "black", width: "100vw", height: "100vh"}}>
            
                
                    <div className={"text-right mb-3"}
                         style={{
                             backgroundColor: "black",
                             position: "absolute",
                             zIndex: "999",
                             right: "5%",
                             bottom: "10%",
                             color: "white",
                             padding: "10px",
                             display: "flex",
                             alignContent: "center",
                             justifyContent: "center",
                             fontSize: "35px"
                         }}>
                            {
                                canBeSkipped ?
                                <button onClick={handleSkip} style={{
                                    fontSize: "32px",
                                    fontWeight: "bolder",

                                }}>Passer la publicité <CloseIcon/></button>
                                :
                                <CountdownCircleTimer
                                    isPlaying
                                    duration={waitTime}
                                    colors={['#004777', '#F7B801', '#A30000', '#A30000']}
                                    colorsTime={[waitTime, 5, 2, 0]}
                                    size={95}
                                    strokeWidth={17}
                                >
                                    {({ remainingTime }) => remainingTime}
                                </CountdownCircleTimer>
                            }
                    </div>
                    
            <video  
             ref={videoRef}
             autoPlay="true"
             muted="true" 
             loop="true" 
             controls="true" 
             playsInLine="false"
             defaultMuted="true"
             style={{width: "100%", height: "100%"}}>
                <source src={`${data.link ?? cachedAd}`} type="video/mp4"/>
            </video>
        </div>
    )
}

export default AdPopup

import React, {useEffect, useState} from 'react';
import './App.css';
import SurveyForm from "./survey-form";
import {Route, Routes} from "react-router-dom";
import SurveyEntry from "./survey-entry.page";
import VideoGallery from "./video.page";
import {SurveyProvider} from "./surveyContext";
import {InstallPWA} from "./installPwa";
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MusicFolder from "./music.page";
import {MaxParticipantProvider} from "./maxParticipantContext";
import VideoModal from "./play-video-autmatic";
import Documentaries from "./video-folder/documentaries";
import VideoClips from "./video-folder/video-clips";
import HumourPage from "./video-folder/humour";
import AdPopup from "./ad-popup";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import ThanksPage from "./thanks-page.page";
import GagnantsPage from './gagnants-page.page';
import ParrainPage from "./parrain-page";
import ThanksParrain from "./thanks-parrain";

const queryClient = new QueryClient()

function App() {
    const [shouldOpenModal, setShouldOpenModal] = useState(false);
    const [video, setVideo] = useState("");

    useEffect(() => {
        async function fetchCachedVideo() {
            const cacheName = 'my-video-cache'; // Replace with your cache name from the service worker
            const videoCurrent = '/videos/play-every-min.mp4'; // Replace with the actual video file path

            setVideo(videoCurrent)
        }

        fetchCachedVideo();
    }, []);


    useEffect(() => {
        // Open the modal every 1 minute
        const interval = setInterval(() => {
            setShouldOpenModal(true);
        }, 1200000); // 1 minute in milliseconds

        return () => {
            clearInterval(interval);
        };
    }, []);

    const handleOpen = () => setShouldOpenModal(true);
    const handleClose = () => setShouldOpenModal(false);

    return (
        <QueryClientProvider client={queryClient}>
            <MaxParticipantProvider>
                <SurveyProvider>
                    {/* <InstallPWA/>  */}
                    <Routes>
                        <Route path='/entry' element={<SurveyEntry/>}/>
                        <Route path='/' element={<SurveyForm/>}/>
                        <Route path='/parrain' element={<ParrainPage/>}/>
                        <Route path='/thanks-parrain' element={<ThanksParrain/>}/>
                        <Route path={'/video'} element={<VideoGallery/>}/>
                        <Route path={'/music'} element={<MusicFolder/>}/>
                        <Route path={'/documentaries'} element={<Documentaries/>}/>
                        <Route path={'/humour'} element={<HumourPage/>}/>
                        <Route path={'/video-clips'} element={<VideoClips/>}/>
                        <Route path={'/advertisement'} element={<AdPopup/>}/>
                        <Route path={'/thanks'} element={<ThanksPage/>}/>
                        <Route path={'/gagnant'} element={<GagnantsPage/>}/>
                    </Routes>
                    <ToastContainer/>

                    {/* {shouldOpenModal &&
                        <VideoModal open={shouldOpenModal} onOpen={handleOpen} onClose={handleClose} videoId={video}/>} */}
                </SurveyProvider>
                {/*<ReactQueryDevtools initialIsOpen={false}/>*/}
            </MaxParticipantProvider>
        </QueryClientProvider>
    );
}

export default App;

import React, {useContext, useEffect, useState} from 'react';
import {addSubmission, db, fetchQuestions, getStoredQuestions} from "./db";
import Logo from './assets/images/logo.png'
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import {SurveyContext} from "./surveyContext";
import axios from "axios";
import {MaxParticipantContext} from "./maxParticipantContext";
import AdPopup from "./ad-popup";
import {useModalStore} from "./store/modalStore";

const alreadyCount = false

const useOnline = () => {
    const [isOnline, setIsOnline] = useState(navigator.onLine);

    useEffect(() => {
        const handleOnline = () => setIsOnline(true);
        const handleOffline = () => setIsOnline(false);

        window.addEventListener('online', handleOnline);
        window.addEventListener('offline', handleOffline);

        return () => {
            window.removeEventListener('online', handleOnline);
            window.removeEventListener('offline', handleOffline);
        };
    }, []);

    return isOnline;
};


const SurveyForm = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const email = searchParams.get('email')
    const [questions, setQuestions] = useState([]);
    const [activeQuestion, setActiveQuestion] = useState(0)
    const {nextUrl, setNextUrl} = useModalStore()
    const [selectedAnswer, setSelectedAnswer] = useState([])
    const [showResult, setShowResult] = useState(false)
    const [selectedAnswerIndex, setSelectedAnswerIndex] = useState(null)
    const {setSurveyCompleted} = useContext(SurveyContext);
    const [result, setResult] = useState({
        score: 0, correctAnswers: 0, wrongAnswers: 0,
    })
    const [selectedOptions, setSelectedOptions] = useState([]);
    const {setMaxParticipant, maxParticipant} = useContext(MaxParticipantContext);

    const toggleOption = (option) => {
        if (selectedOptions.includes(option)) {
            setSelectedOptions(selectedOptions.filter((item) => item !== option));
        } else {
            setSelectedOptions([...selectedOptions, option]);
        }
    };

    const isOnline = useOnline();

    useEffect(() => {
        if (!email) {
            navigate({
                pathname: '/',
            });
        }

        console.log(isOnline)
        const fetchSurveyQuestions = async () => {
            if (isOnline) {
                await fetchQuestions();
            }

            const storedQuestions = await getStoredQuestions();
            console.log(storedQuestions)
            setQuestions(storedQuestions);
        };

        fetchSurveyQuestions();
    }, [email, isOnline, navigate]);

    const questionObject = questions[0]?.questions[activeQuestion]

    const onClickNext = () => {
        setSelectedAnswerIndex(null)
        setResult((prev) => selectedAnswer ? {
            ...prev, score: prev.score + 5, correctAnswers: prev.correctAnswers + 1,
        } : {...prev, wrongAnswers: prev.wrongAnswers + 1})
        if (activeQuestion !== questions[0]?.questions?.length - 1) {
            setActiveQuestion((prev) => prev + 1)
        } else {
            setActiveQuestion(0)
            setShowResult(true)
        }
    }

    const onAnswerSelected = (question, answer, type) => {
        setSelectedAnswer((prevSelectedOptions) => {
            const existingOption = prevSelectedOptions.find((item) => item.question === question);
            if (existingOption) {
                return prevSelectedOptions.map((item) => {
                    if (item.question === question) {
                        return {
                            ...item, options: [...item.options, answer],
                        };
                    }
                    return item;
                });
            } else {
                return [...prevSelectedOptions, {
                    question, options: [answer],
                },];
            }
        });

        setSelectedAnswerIndex(true)
    }


    const addLeadingZero = (number) => (number > 9 ? number : `0${number}`)

    const handleSubmit = async (e) => {
        e.preventDefault();

        console.log("Handle submit")

        if (!isOnline) {
            let surveyId = questions[0]?.id
            addSubmission({surveyId, email, selectedAnswer});
            // toast.error('Formulaire soumis hors ligne. Il sera synchronisé lorsque le réseau sera disponible.');
            setMaxParticipant(maxParticipant - 1)
            setSurveyCompleted(true)
            navigate({
                pathname: '/thanks'
            });
            return;
        }

        try {
            const body = {
                email: email,
                selectedAnswer: selectedAnswer
            }
            const response = await axios.post('https://admin-draw-video.masscom-ci.com/api/submit', body)

            console.log("Response")
            console.log(response)

            if (response?.data?.status) {
                // toast.success('Félicitations ! Vous pouvez à présent accéder aux vidéos / Playlists');
                setSurveyCompleted(true)
                setMaxParticipant(maxParticipant - 1)
                setNextUrl('/thanks')
                navigate({
                    // pathname: '/advertisement'
                    pathname: '/thanks'
                });
            } else {
                // toast.error("Accédez aux vidéos /Playlists")
                setSurveyCompleted(true)
                setNextUrl('/thanks')
                navigate({
                    // pathname: '/advertisement'
                    pathname: '/thanks'
                });
            }
        } catch (error) {
            console.log(error?.response?.data?.message)
            // toast.error(error?.response?.data?.message);
        }
    };

   

    useEffect(() => {
        const fetchMaxPart = async () => {
            const maxPart = await db.maxParticipant.toArray();

            console.log("Max part")
            console.log(maxPart)

            setMaxParticipant(maxPart[0]?.maxPart)
        };

        fetchMaxPart()
    }, [])

    return (<>
        <div style={{
            width: '100vw',
            height: '100vh',
            backgroundPosition: "center",
            backgroundImage: `url(${questions[0]?.company_bg})`
        }}>
            <nav style={{textAlign: "center"}}>
                <img src={Logo} style={{width: '200px', height: "200px", margin: "auto"}} alt={"logo"}/>
            </nav>
            <div className="quiz-container">
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
            </div>
            <AdPopup/>
        </div>
    </>)
};

export default SurveyForm;

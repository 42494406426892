import React, {useContext, useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import Logo from "./assets/images/logo.png";
import {SurveyContext} from "./surveyContext";
import {Controller, useForm} from "react-hook-form";
import axios from "axios";
import {addParentSubmission, db, syncParentSubmissions} from "./db";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {fa, faLocation, faUser} from '@fortawesome/free-solid-svg-icons';
import CloseIcon from "@mui/icons-material/Close";

import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';


const spanStyle = {
    padding: '20px',
    // background: '#efefef',
    // color: '#000000'
  }
  
  const divStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundSize: 'cover',
    height: '100vh'
  }
  

const useOnline = () => {
    const [isOnline, setIsOnline] = useState(navigator.onLine);
    useEffect(() => {
        const handleOnline = () => setIsOnline(true);
        const handleOffline = () => setIsOnline(false);

        window.addEventListener('online', handleOnline);
        window.addEventListener('offline', handleOffline);

        return () => {
            window.removeEventListener('online', handleOnline);
            window.removeEventListener('offline', handleOffline);
        };


    }, []);

    return isOnline;
};


const ParrainPage = () => {
    const navigate = useNavigate();
    const {control, register, handleSubmit, formState: {errors}} = useForm();
    const [questions, setQuestions] = useState([]);
    const isOnline = useOnline();

   

    useEffect(() => {
        if (isOnline) {
            syncParentSubmissions();
        }
    }, [isOnline]);


    const [code_parrain, setCodeParrain] = useState();

    useEffect(() => {
        fetchNextParrainCode()
    }, [isOnline, navigate]);


    const fetchNextParrainCode = async () => {
        try {
            if (isOnline) {
                const response = await axios.get('https://admin-draw-video.masscom-ci.com/api/parrain/next-code');
                const code_parrain = response.data?.data?.code_parrain

                await setCodeParrain(code_parrain)
                
            }

        } catch (error) {
            console.error('Error fetching next code parrain from API:', error);
        }
    };


    const [objectifs, setObjectifs] = useState([]);

    useEffect(() => {
        fetchObjectifs();
    }, [isOnline, navigate]);


    const fetchObjectifs = async () => {
        try {
            if(isOnline) {
                const response = await axios.get('https://admin-draw-video.masscom-ci.com/api/parrain/objectif');
            
                setObjectifs(response.data?.data.objectifs);
              
            }
        } catch (error) {
            console.error('Error fetching objectifs from API:', error);
        }
    }

    
    const onSubmit = async (e) => {
        try {
            const email = e?.email
            // const code_parrain = e?.code_parrain
            const commune = e?.commune
            const body = {
                email: email, 
                commune: commune, 
                code_parrain: code_parrain};

            const emails = await db.emails.toArray();

            console.log(isOnline)
            console.log("On Submit sahady")

            if (!isOnline) {
                const isAlready = emails?.find((v) => v?.email === email)
                db.emails.add({email});

                addParentSubmission(body);

                if (isAlready) {
                    // toast.error("Accédez aux vidéos /Playlists")
                    navigate({
                        pathname: '/thanks-parrain', search: `?parrain=${code_parrain}`
                    });
                    return;
                }
                // toast.error('Formulaire soumis hors ligne. Il sera synchronisé lorsque le réseau sera disponible.');
                navigate({
                    pathname: '/thanks-parrain', search: `?parrain=${code_parrain}`
                });
                return;
            }

            const response = await axios.post('https://admin-draw-video.masscom-ci.com/api/parrain/submit', body)
            

            navigate({
                pathname: '/thanks-parrain', search: `?parrain=${code_parrain}`
            });
        } catch (error) {
            // toast.error(error?.response?.data?.message);
            
            navigate({
                pathname: '/thanks-parrain', search: `?parrain=${code_parrain}`
            });
        }
    }

    const [disableBtn, setDisableBtn] = React.useState("");
    const [term, setTerm] = React.useState(true);
    
    // var term = true;

    const handleChange = (event) => {
        console.log(term)
        if (term) {
            setTerm(false)
            setDisableBtn(true)
        }
        else {
            setTerm(true)
            setDisableBtn(false)
        }
    };

    return (
        <>
           <div style={{
                        width: '120vw',
                        height: '120vh',
                        backgroundPosition: "center",
                        backgroundImage: `url(${questions[0]?.company_bg})`
                    }}>
                        <nav style={{textAlign: "center"}}>
                            <img src={Logo} style={{width: '180px', height: "160px", margin: "auto"}} alt={"logo"}/>
                        </nav>

                        <div className="quiz-container" >
                            <p style={{
                                textTransform: "uppercase",
                                fontWeight: "bolder",
                                lineHeight: "2rem",
                                fontSize: "22px",
                                fontStyle: "italic",
                                textAlign: "center",
                                textDecoration: "underline",
                                background: "#ffff00a3",
                                padding: "5px",
                            }}>
                                CREER VOTRE PROFIL "PARRAIN"
                            </p>

                             
                            
                            <form onSubmit={handleSubmit(onSubmit)}>

                               
                            <div className={"flex flex-col my-5"}>
                                   
                                   <label htmlFor="email" className={"font-bold"} style={{
                                           fontSize: "19px",
                                       }}>Entrez votre numéro</label>
                                       <Controller
                                           name="email"
                                           control={control}
                                           rules={{
                                               required: 'This field is required',
                                               validate: (value) => {
                                                   // Define a regex pattern for phone numbers and email addresses
                                                   const regex = /^(?:\d{10})$/;
                                                   return regex.test(value) || "Merci d’entrer un numéro téléphone valide";
                                               },
                                           }}
                                           render={({field}) => (
                                               <>

<div class="input-group mb-3">
                                           <div class="input-group-prepend">
                                               <span class="input-group-text indicatif" id="basic-addon1">+225</span>
                                           </div>
                                             <input {...register("email", {required: true})} type="text"
                                                       id="email" name="email"
                                                       placeholder="" className="form-control"/>
                                                   {errors.email && (
                                                       <p className="error-message text-red-400">
                                                           {errors.email.message}
                                                       </p>
                                                   )}
                                       </div>
                                                 
                                               </>
                                           )}
                                       />
                               </div>


                               <div className={"flex flex-col my-3"}>
                                   
                                    <label htmlFor="commune" className={"font-bold"} style={{
                                            fontSize: "19px",
                                        }}>Entrez votre commune</label>
                                        <Controller
                                            name="commune"
                                            control={control}
                                            rules={{
                                                required: 'This field is required',
                                                // validate: (value) => {
                                                //     // Define a regex pattern for phone numbers and commune addresses
                                                //     const regex = /^(?:\d{10})$/;
                                                //     return regex.test(value) || "Merci d’entrer un numéro téléphone valide";
                                                // },
                                            }}
                                            render={({field}) => (
                                                <>

<div class="input-group mb-3">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text indicatif" id="basic-addon1">
                                                    <FontAwesomeIcon icon={faLocation}  style={{
                                                    margin: "0 auto",
                                                    textAlign: "center",
                                                    width: "100%",
                                                 }}/>
                                                </span>
                                            </div>
                                              <input {...register("commune", {required: true})} type="text"
                                                        id="commune" name="commune"
                                                        placeholder="" className="form-control"/>
                                                    {errors.commune && (
                                                        <p className="error-message text-red-400">
                                                            {errors.commune.message}
                                                        </p>
                                                    )}
                                        </div>
                                                  
                                                </>
                                            )}
                                        />
                                </div>
                               
                               
                               <div className={"flex flex-col my-3"}>
                                        <input {...register("code_parrain", {required: false})} type="hidden"
                                                       name="code_parrain"
                                                       value={code_parrain}/>

                                        <p style={{
                                            fontSize: "26px",
                                            textAlign: "center",
                                        }}>
                                            Votre code de parrainage est :  
                                            <br/>
                                            <span style={{
                                                fontSize: "50px",
                                                fontWeight: "bolder",
                                                // background: "#ffff00a3",
                                            }}> {code_parrain} </span>
                                            <br/>
                                            <span style={{
                                                textDecoration: "underline",
                                                fontWeight: "bolder",
                                                // background: "#ffff00a3",
                                            }}>Merci de mémoriser votre code</span>
                                        </p>
                                        
                               </div>

                                <button type="submit" disabled={disableBtn} className={"w-100"}><span className={"text-2xl"}>ENVOYER</span></button>

                                <p style={{
                                fontSize: "20px",
                                fontWeight: "bolder",
                                marginTop: "10%",
                            }}>Les lots que vous pouvez gagner selon les objectifs atteints</p>

                                <ul style={{
                                fontSize: "20px",
                                marginLeft: "3%",
                            }}>
                                {
                                    objectifs.map((objectif) => (
                                        <li style={{
                                            marginTop: "0px",
                                            border: "unset",
                                            listStyle: "disclosure-closed",
                                            fontSize: "20px",
                                        }}><strong>{objectif.objectif}</strong> parrainages = Lot valeur de <strong>{objectif.lot}</strong> CFA</li>
                                    ))
                                }
                            </ul>

                            </form>
                        </div>
                    </div>

            
        </>
    )
}

export default ParrainPage
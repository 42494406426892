import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Logo from "./assets/images/logo.png";
import { SurveyContext } from "./surveyContext";
import { Controller, useForm } from "react-hook-form";
import axios from "axios";
import { db, fetchQuestions, getStoredQuestions, syncSubmissions } from "./db";
import { useModalStore } from "./store/modalStore";
import AdPopup from "./ad-popup";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {fa, faUser} from '@fortawesome/free-solid-svg-icons';

import CloseIcon from "@mui/icons-material/Close";

import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';


const spanStyle = {
    padding: '20px',
    // background: '#efefef',
    // color: '#000000'
  }
  
  const divStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundSize: 'cover',
    height: '100vh'
  }
  

const useOnline = () => {
    const [isOnline, setIsOnline] = useState(navigator.onLine);
    useEffect(() => {
        const handleOnline = () => setIsOnline(true);
        const handleOffline = () => setIsOnline(false);

        window.addEventListener('online', handleOnline);
        window.addEventListener('offline', handleOffline);

        return () => {
            window.removeEventListener('online', handleOnline);
            window.removeEventListener('offline', handleOffline);
        };


    }, []);

    return isOnline;
};


const SurveyEntry = () => {
    const navigate = useNavigate();
    const { setSurveyCompleted } = useContext(SurveyContext);
    const { control, register, handleSubmit, formState: { errors } } = useForm();
    const [savedEmails, setSavedEmails] = useState([]);
    const [questions, setQuestions] = useState([]);
    const isOnline = useOnline();
    const [maxParticipant, setMaxParticipant] = useState(0)
    const { nextUrl, setNextUrl } = useModalStore()



    useEffect(() => {
        if (isOnline) {
            syncSubmissions();
        }
    }, [isOnline]);


    useEffect(() => {
        // Call the function to fetch saved emails when the component mounts
        if (isOnline) {
            fetchEmailsFromApi()
        }
        if (!isOnline) {
            fetchSavedEmails()
        }
    }, [isOnline, maxParticipant, setMaxParticipant]);

    const fetchSavedEmails = async () => {
        // Function to fetch saved emails from the database and update state
        const emails = await db.emails.toArray();
        setSavedEmails(emails);
    };

    useEffect(() => {
        const fetchMaxPart = async () => {
            const maxPart = await db.maxParticipant.toArray();
            setMaxParticipant(maxPart[0]?.maxPart)
        };

        fetchMaxPart()

        if (maxParticipant <= 0) {
            setSurveyCompleted(true)
        }
    }, [maxParticipant])


    const fetchEmailsFromApi = async () => {
        try {
            if (isOnline) {
                const response = await axios.get('https://admin-draw-video.masscom-ci.com/api/answers');
                const emailsFromApi = response.data?.data?.emails;
                await db.emails.clear()
                await saveEmailsToDB(emailsFromApi);
                await fetchSavedEmails();
            }

        } catch (error) {
            console.error('Error fetching emails from API:', error);
        }
    };

    const saveEmailsToDB = async (emails) => {
        for (const email of emails) {
            await db.emails.add({ email });
        }
    };

    const fetchSurveyQuestions = async () => {
        if (isOnline) {
            await fetchQuestions();
        }
        const storedQuestions = await getStoredQuestions();
        setQuestions(storedQuestions);
    };

    useEffect(() => {
        fetchSurveyQuestions();

    }, [isOnline, navigate]);

    const onSubmit = async (e) => {
        try {
            const email = e?.email
            const body = { email: email };
            const emails = await db.emails.toArray();

            console.log(isOnline)
            console.log("On Submit sahady")

            if (!isOnline) {
                const isAlready = emails?.find((v) => v?.email === email)
                db.emails.add({ email });

                if (isAlready) {
                    // toast.error("Accédez aux vidéos /Playlists")
                    setSurveyCompleted(true)
                    navigate({
                        pathname: '/thanks'
                    });
                    return;
                }
                // toast.error('Formulaire soumis hors ligne. Il sera synchronisé lorsque le réseau sera disponible.');
                navigate({
                    // pathname: '/survey', search: `?email=${email}`,
                    pathname: '/thanks'
                });
                return;
            }

            const response = await axios.post('https://admin-draw-video.masscom-ci.com/api/check_email', body)

            if (response?.data?.status) {
                setSurveyCompleted(true)
                setNextUrl('/thanks')
                navigate({
                    // pathname: '/survey', search: `?email=${email}`,
                    pathname: '/thanks'
                });
            } else {
                // toast.success("Accédez aux vidéos /Playlists")
                setSurveyCompleted(true)
                setNextUrl('/thanks')
                navigate({
                    pathname: '/thanks'
                });
            }
        } catch (error) {
            // toast.error(error?.response?.data?.message);
            setSurveyCompleted(true)
            navigate({
                pathname: '/thanks'
            });
        }
    }

    const [disableBtn, setDisableBtn] = React.useState("");
    const [term, setTerm] = React.useState(true);
    
    // var term = true;

    const handleChange = (event) => {
        console.log(term)
        if (term) {
            setTerm(false)
            setDisableBtn(true)
        }
        else {
            setTerm(true)
            setDisableBtn(false)
        }
    };

    const [slideImages, setSlideImages] = useState([]);

    useEffect(() => {
        fetchGagnantsFromApi()
    }, [isOnline, navigate]);


    const fetchGagnantsFromApi = async () => {
        try {
            if (isOnline) {
                const response = await axios.get('https://admin-draw-video.masscom-ci.com/api/gagnants');
                const gagnants = response.data?.data?.gagnants
                console.log(gagnants)
                setSlideImages(gagnants)
            }

        } catch (error) {
            console.error('Error fetching gagnants from API:', error);
        }
    };

    const [gagnant, setGagnant] = React.useState(false);

    const showGagnant = (event) => {
        setGagnant(true)
    }

    const closeGagnant = (event) => {
        setGagnant(false)
    }

    const goParrain = () => {
        navigate({
            pathname: '/parrain'
        });
    }

  

    return (
        <>{
<div style={{
                        width: '120vw',
                        height: '120vh',
                        backgroundPosition: "center",
                        backgroundImage: `url(${questions[0]?.company_bg})`
                    }}>
                        <nav style={{textAlign: "center"}}>
                            <img src={Logo} style={{width: '180px', height: "160px", margin: "auto"}} alt={"logo"}/>
                        </nav>

                        <div className="quiz-container" >
                            <p onClick={showGagnant} style={{
                                textTransform: "uppercase",
                                fontWeight: "bolder",
                                lineHeight: "2rem",
                                fontSize: "21px",
                                fontStyle: "italic",
                                textAlign: "left",
                                textDecoration: "underline",
                                background: "#ffff00a3",
                                padding: "5px",
                            }}>
                                VOICI NOTRE 1er GAGNANT DE LA SEMAINE !!
                                CHAQUE JOUR TENTEZ VOTRE CHANCE ! CLIQUEZ 
                            </p>

                            <ul style={{
                                fontSize: "20px",
                                marginLeft: "3%",
                            }}>
                                <li style={{
                                    marginTop: "0px",
                                     border: "unset",
                                     listStyle: "disclosure-closed",
                                     fontSize: "20px",
                                }}>Entrez votre numéro</li>
                                {/* <li style={{
                                    marginTop: "0px",
                                     border: "unset",
                                     listStyle: "disclosure-closed",
                                     fontSize: "20px",
                                }}>Répondez à une question</li> */}
                                <li style={{
                                    marginTop: "0px",
                                    fontWeight: "bold",
                                    textDecoration: "underline",
                                    border: "unset",
                                    listStyle: "disclosure-closed",
                                    fontSize: "20px",
                                    color: "#e60303",
                                    textTransform: "uppercase",
                                }}>Gagnez un smartphone de plus de 120 000 CFA !! Tirage chaque semaine</li>

                            </ul>

                    <form onSubmit={handleSubmit(onSubmit)}>

                        <div className={"flex flex-col my-3"}>

                            <label htmlFor="email" className={"font-bold"} style={{
                                fontSize: "20px",
                            }}>Entrez votre numéro</label>
                            <Controller
                                name="email"
                                control={control}
                                rules={{
                                    required: 'This field is required',
                                    validate: (value) => {
                                        // Define a regex pattern for phone numbers and email addresses
                                        const regex = /^(?:\d{10})$/;
                                        return regex.test(value) || "Merci d’entrer un numéro téléphone valide";
                                    },
                                }}
                                render={({ field }) => (
                                    <>
                                        <div class="input-group mb-3">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text indicatif" id="basic-addon1">+225</span>
                                            </div>
                                            <input {...register("email", { required: true })} type="text"
                                                id="email" name="email"
                                                placeholder="" className="form-control"/>
                                            {errors.email && (
                                                <p className="error-message text-red-400">
                                                    {errors.email.message}
                                                </p>
                                            )}
                                        </div>

                                    </>
                                )}
                            />
                        </div>

                        <button type="submit" disabled={disableBtn} className={"w-100"}><span className={"text-2xl"}>CLIQUEZ</span></button>

<div className={"flex flex-col my-3"}>
   
   <label htmlFor="code_parrain" className={"font-bold"} style={{
           fontSize: "19px",
       }}>Code parrainage (Facultatif)</label>
       <Controller
           name="code_parrain"
           control={control}
           rules={{
               required: false,
            //    validate: (value) => {
            //        // Define a regex pattern for phone numbers and email addresses
            //        const regex = /^(?:\d{4})$/;
            //        return regex.test(value) || "Merci d’entrer un code de parrainage valide";
            //    },
           }}
           render={({field}) => (
               <>

    <div class="input-group mb-3" style={{
        width: "40%",
    }}>
           <div class="input-group-prepend" style={{
            width: "65px",
           }}>
               <span class="input-group-text indicatif" id="basic-addon2">
                 <FontAwesomeIcon icon={faUser} style={{
                    margin: "0 auto",
                    textAlign: "center",
                    width: "100%",
                 }}/>
               </span>
           </div>
             <input {...register("code_parrain", {required: false})} type="number"
                       id="code_parrain" name="code_parrain"
                       max={9999}
                       placeholder="" className="form-control" style={{
                        height: "50px",
                        marginTop: "8px",
                       }}/>
                   {errors.code_parrain && (
                       <p className="error-message text-red-400">
                           {errors.code_parrain.message}
                       </p>
                   )}
            
       </div>
                 
               </>
           )}
       />
</div>

<p style={{
                textAlign: "left",
                fontSize: "19px",
                fontWeight: "bold",
                marginTop: "-6%",
            }}>
                <a href="#" onClick={goParrain} class="link" style={{
                    textDecoration: "underline",
                }}>CLIQUER POUR DEVENIR PARRAIN</a>
            </p>

<div className={"flex flex-row my-2"} style={{
    gap:"0%"
}}>
    <img src="/iphones.png" style={{width: '18%', margin:"0 auto"}} alt={"iphone"}/>
    <img src="/iphone.jpg" style={{width: '18%', margin:"0 auto"}} alt={"iphone"}/>
    <img src="/samsu.webp" style={{width: '18%', margin:"0 auto"}} alt={"samsung"}/>
</div>                                                        


<div className={"flex flex-row my-2"}>
    <Controller
        name="terms"
        control={control}
        rules={{
            required: 'This field is required'
        }}
        render={({field}) => (
            <>
                <input {...register("terms")} required={true} type="checkbox"
                       id="terms" className={"w-25"} name="terms" onClick={handleChange} checked={term}
                       placeholder=""/>
                {errors.terms && (
                    <p className="error-message text-red-400">
                        {errors.terms.message}
                    </p>
                )}
            </>
        )}
    />
    <label htmlFor="terms" style={{
        fontSize: "17px"
    }}>J'accepte les <a href="" className={"link"}>termes et conditions</a></label>
</div>

<div className={"flex flex-row my-3"}>
    <p style={{
        fontSize: "11px"
    }}> * Dans la limite de cinq (5) Smarphones au choix de l’annonceur, tirage tous les trente (30) jours.</p>
</div>

                    </form>
                </div>
            </div>
}
{
                gagnant ?
                <div style={{
                    color: "white",
                    position: "absolute", 
                    top: 0, 
                    left: 0, 
                    backgroundColor: "black", 
                    width: "100vw", 
                    height: "100vh"}}>
                        <div className={"text-right mb-3"}
                                    style={{
                                        backgroundColor: "black",
                                        position: "absolute",
                                        zIndex: "999",
                                        right: "5%",
                                        bottom: "10%",
                                        color: "white",
                                        padding: "10px",
                                        display: "flex",
                                        alignContent: "center",
                                        justifyContent: "center",
                                    }}>
                                    <button onClick={closeGagnant} style={{
                                        fontSize: "20px",
                                    }}>Fermer<CloseIcon/></button>
                                </div>
                        <div className="slide-container" style={{
                            width: "90%",
                            margin: "0 auto",
                        }}>
                            <Slide autoplay="true">
                                {slideImages.map((slideImage, index)=> (
                                    <div key={index}>
                                    <div style={{ ...divStyle, 'backgroundImage': `url(${slideImage.url})` }}>
                                        {/* <span style={spanStyle}>{slideImage.caption}</span> */}
                                        <span style={spanStyle}>&nbsp; &nbsp;</span>
                                    </div>
                                    </div>
                                ))} 
                            </Slide>
                        </div>
                    </div>
            :
            null
            }
        </>
        
    )
}

export default SurveyEntry